<template>
<div id="explore" class="page-content single-page">
 <div class="body">
   <div class="cover-container">
     <img class="cover-bg" v-if="loadedContent.imageURL != null" :src="loadedContent.imageURL">
   </div>

    <div class="overflow">
     <div class="title set-width">
       <h1>{{loadedContent.name}}</h1>
        <p>{{loadedContent.description}}</p>
     </div>
   </div>

 </div>

</div>
</template>

<script>
import {
 projectFirestore
} from '../main'


export default {
 components: {
 },
 data() {
  return {
    loadedContent: {
      "name": null,
      "description": null,
      "createdBy": null,
      "imageURL": null,
    }
  };
 },
 mounted: function() {
  this.onLoad()
 },
 methods: {
  onLoad() {
    const brandMixesDocRef = projectFirestore.collection('brand-mixes').doc(this.$root.viewingPage.contentID);
    const publicMixesDocRef = projectFirestore.collection('public-mixes').doc(this.$root.viewingPage.contentID);

    // Check if the contentID is in the 'brand-mixes' collection
    brandMixesDocRef.get().then((doc) => {
      if (doc.exists) {

        // It's in 'brand-mixes' collection
        const docData = doc.data();
        docData.id = doc.id;
        this.$root.$refs.customSoundMixer.personalMixes.push(docData);
        this.loadedContent = {
          "name": docData.name,
          "description": docData.description,
          "imageURL": docData.imageURL
        }
        if (this.$root.playingCustomMixId != doc.id) {
          this.$root.$refs.customSoundMixer.playCustomMix(doc.id);
        }
      } else {
        // Check if the contentID is in the 'public-mixes' collection
        publicMixesDocRef.get().then((doc) => {
          if (doc.exists) {
            // It's in 'public-mixes' collection
            const docData = doc.data();
            docData.id = doc.id;
            this.$root.$refs.customSoundMixer.personalMixes.push(docData);
            this.loadedContent = {
              "name": docData.name,
              "description": docData.description,
              "imageURL": docData.imageURL
            }
            if (this.$root.playingCustomMixId != doc.id) {
              this.$root.$refs.customSoundMixer.playCustomMix(doc.id);
            }
          } else {
            console.log('Document not found in both collections');
          }
        }).catch((error) => {
          console.log('Error getting document from public-mixes:', error);
        });
      }
    }).catch((error) => {
      console.log('Error getting document from brand-mixes:', error);
    });


  },

 },
};
</script>

<style lang="scss">
.cover-container {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -2;
  &:before {
    content: "";
    background: rgb(22, 23, 40);
    display: block;
    position: absolute;
    width: 100%;
    aspect-ratio: 16 / 9;
    top: 15vw;
    z-index: -1;
    background: rgb(22,23,40);
    background: -moz-linear-gradient(0deg, rgba(22,23,40,1) 0%, rgba(22,23,40,1) 60%, rgba(22,23,40,0) 100%);
    background: -webkit-linear-gradient(0deg, rgba(22,23,40,1) 0%, rgba(22,23,40,1) 60%, rgba(22,23,40,0) 100%);
    background: linear-gradient(0deg, rgba(22,23,40,1) 0%, rgba(22,23,40,1) 60%, rgba(22,23,40,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#161728",endColorstr="#161728",GradientType=1);
  }
  &:after {
    content: "";
    background: rgb(22, 23, 40);
    display: block;
    position: absolute;
    width: 100%;
    height: calc(100% - 50vw);
    aspect-ratio: 16 / 9;
    top: 50vw;
    z-index: -1;
    background: rgb(22, 23, 40);

  }
  .cover-bg {
    width: 100%;
    z-index: -2;
    position: relative;
  }
}

#app #explore.page-content .body .overflow {
  padding-bottom: 50px !important;
}

</style>
