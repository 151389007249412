<!-- MainComponent.vue -->
<template>
  <form class="updateName-form" @submit.prevent="submit">
    <span @click="this.$root.overlayToggle()" class="close material-symbols-rounded"> &#xe5cd; </span>

    <div class="header" v-if="showDelete == false">
      <h2>Update Mix Name</h2>
    </div>
    <div class="header" v-if="showDelete == true">
      <h2>Are you sure you want to delete this mix?</h2>
    </div>

    <div class="input-group" v-if="showDelete == false">
      <label for="email">Mix name</label>
      <input
        type="text"
        id="mixName"
        v-model="this.$root.updateMixNameItemName"
        placeholder="Enter your email address"
       />
    </div>

<div class="col-2">

  <div class="deleteItem" @click="showDelete = true" v-if="showDelete == false">Delete Mix</div>
  <button :disabled="this.$root.updateMixNameItemName.length < 1" v-if="showDelete == false" name="button">Update</button>

  <div class="confirmDelete" v-if="showDelete">
    <div class="deleteItem cancel" @click.prevent="showDelete = false">Cancel</div>
    <div class="deleteItem" @click.prevent="deleteItem()">Delete</div>
  </div>
</div>

  </form>

  <form >
  </form>

</template>

<script>
import { projectAuth, projectFirestore } from '../main'

export default {
  data() {
    return {
      email: null,
      password: null,
      errorMessage: false,
      showDelete: false
    };
  },
  methods: {
    submit() {

      projectAuth.onAuthStateChanged((user) => {
       const uid = user.uid

       projectFirestore.collection("users").doc(uid).collection("personal-mixes").doc(this.$root.updateMixNameModalID).update({
         name: this.$root.updateMixNameItemName
       }).then(() => {
        // Show the save confirmation message
        this.$root.showSaveMessage = true;
        this.$root.overlayToggle()
        setTimeout(() => {
         this.$root.showSaveMessage = false;
        }, 6000);
       });
      })

    },

    deleteItem() {
      projectAuth.onAuthStateChanged((user) => {
       const uid = user.uid
       projectFirestore.collection("users").doc(uid).collection("personal-mixes").doc(this.$root.updateMixNameModalID).delete()
       this.$root.showSaveMessage = true;
       this.$root.overlayToggle()
       setTimeout(() => {
        this.$root.showSaveMessage = false;
       }, 6000);
     })

    }

  },
};
</script>


<style lang="scss" scoped>
  .updateName-form {

    .header {
      width: 100%;
    }
    .input-group {
      display: flex;
      flex-direction: column;
      width: 100%;

    }
    input {
      height: 55px;
      border-radius: 8px;
      background: none;
      border: solid 1px #36395c;
      padding: 0px 22px;
      color: #ffffff;
      font-family: "Manrope", sans-serif !important;
      font-size: 16px;
      margin-top: 8px;
      margin-bottom: 16px;

      &:not(:disabled) {
        &:hover {
          border: solid 1px #4e5387;
        }
        &:active {
          border: solid 1px #4e5387;
        }
        &:focus {
          z-index: 1;
          position: relative;
          outline: none;
          box-shadow:0 0 0 3px #c09707;
        }
      }
    }
    .confirmDelete {
      width: 100%;
      display: flex;
      gap: 12px;
      .cancel {
        color: inherit !important;

        &:hover {
          background-color: var(--vc-clr-secondary);
          border-color: transparent;
        }
      }
      .deleteItem:not(:hover) {
        color: #be4141;
      }
    }
    .deleteItem {
      height: 55px;
      width: 100%;
      border-radius: 8px;
      font-weight: 900;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Manrope", sans-serif !important;
      transition: all ease-in-out 0.2s;
      cursor: pointer;
      border: solid;

      &:hover {
        border-color: #be4141;
        background-color: #be4141;
      }
    }
  }
  .col-2 {
    display: flex;
    width: 100%;
    gap: 12px;
  }
</style>
