<template>
  <div class="sound-mixer">
    <input
      ref="rangeItem"
      type="range"
      :id="name"
      v-model="volume"
      @input="adjustVolume"
      min="0"
      max="100"
      step="1"
      class="slider"
    />
    <div class="visualSlider">
      <div class="volume">
        {{ volume }}%
      </div>
      <div class="icon">
        <img :src="icon" :alt="name">
      </div>
      <div class="visual-bar" :style="'height: calc(' + volume + '% - 8px)'">
      </div>
    </div>
    <label :for="name">{{ label }}</label>
  </div>
</template>

<script>
import { Howl } from 'howler';
import RangeTouch from './js/rangetouch';

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    audioSrc: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      volume: 0,
      isPlaying: false,
      sound: null, // Howler sound object
      incremented: false, // Track increment status
    };
  },
  mounted() {
    // Initialize RangeTouch for the input slider
    new RangeTouch(this.$refs.rangeItem, {
      thumbWidth: 100
    });
  },
  watch: {
    isPlaying(newValue) {
      if (newValue === true) {
        this.$root.playingCount += 1;
      } else {
        this.$root.playingCount -= 1;
      }
    },
  },
  methods: {
    initializeSound() {
      // Define the length of your audio file in milliseconds
      const audioLength = 30000; // For example, 30 seconds

      // Initialize Howler.js sound with a sprite
      this.sound = new Howl({
        src: [this.audioSrc],
        sprite: {
          loop: [0, audioLength] // Define the start and end times in milliseconds
        },
        volume: this.volume / 100,
        loop: true
      });
    },
    adjustVolume() {
      if (!this.sound) {
        this.initializeSound(); // Initialize sound on first interaction
      }

      if (this.$root.currentSoundMix != null) {
        const savedPlaylistMixItemVolume = this.$root.currentSoundMix[this.name].volume;
        const adjustedVolume = this.volume;

        if (savedPlaylistMixItemVolume != adjustedVolume) {
          this.$root.saveAdjustedMix = true;
          if (!this.incremented) {
            this.$root.customAdjustedItems += 1;
            this.incremented = true;
          }
        } else {
          if (this.incremented && this.$root.customAdjustedItems > 0) {
            this.$root.customAdjustedItems -= 1;
            this.incremented = false;
          }
          if (this.$root.customAdjustedItems === 0) {
            this.$root.saveAdjustedMix = false;
          }
        }
      }

      if (this.sound) {
        this.sound.volume(this.volume / 100);
      }

      if (this.volume > 0) {
        if (!this.isPlaying) {
          this.sound.play('loop');
          this.isPlaying = true;
        }
      } else {
        this.sound.pause();
        this.isPlaying = false;
      }
    },
    stop() {
      this.volume = 0;
      this.isPlaying = false;
      if (this.sound) {
        this.sound.stop();
      }
    },
    pause() {
      this.isPlaying = false;
      if (this.sound) {
        this.sound.pause();
      }
    },
    continuePlaying() {
      this.isPlaying = true;
      if (this.sound) {
        this.sound.play('loop');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.upgradeModel {
  &:after {
    background-image: url('@/assets/upgrade-stars.png');
    content: "";
    display: block;
    position: absolute;
    width: 0%;
    top: 0;
    height: 100%;
    width: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    z-index: -1;
  }

  .header {
    gap: 12px;
    padding-bottom: 12px !important;
  }

  .feature-container {
    margin-bottom: 4px !important;
  }

  .login-form {
    width: 100%;
  }
}
</style>



<style lang="scss" scoped>
.sound-mixer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  height: 300px;
  width: 120px;
  position: relative;
  margin-right: 22px;
  flex-grow: 1;
  max-width: 120px;
  min-width: 120px;

  @media only screen and (max-width: 650px) {
    min-width: unset;
    width: calc(50% - 22px);
    max-width: calc(50% - 42px);
    height: 200px;

  }

  label {
    position: absolute;
    bottom: -32px;
    color: #ffff;
    font-size: 14px;

        @media only screen and (max-width: 650px) {
          bottom: -28px;
          font-size: 12px;
        }
  }
  .slider {
    width: 290px;
    height: 120px;
    transform: rotate(-90deg);
    margin: 0;
    position: absolute;
    top: 93px;
    z-index: 3;
    cursor: pointer;
    opacity: 0;


    @media only screen and (max-width: 650px) {
      width: 210px;
      top: 40px;
    }


    &:focus, &:active, &:target {
      + .visualSlider {
        border: solid 1px var(--primary-color);
      }
    }

    + .visualSlider {
      background-color: var(--mixer-background);
      border: solid 1px var(--mixer-border);
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      z-index: 1;
      border-radius: 8px;

      .volume {
        position: absolute;
        z-index: 2;
        width: calc(100% - 34px);
        text-align: center;
        padding: 17px;
        color: var(--base-font-colour);
        opacity: 0;
        top: 50%;
        font-size: 14px;
      }
      .icon {
        position: absolute;
        z-index: 2;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        text-align: center;
        color: #fff;
        img {
          margin-bottom: 10px;
          width: 36px;
        }
      }

      .visual-bar {
        z-index: 1;
        width: calc(100% - 8px);
        position: absolute;
        bottom: 0;
        display: inline-block;
        left: 0;
        background: var(--mixer-bar);
        border-radius: 8px;
        margin: 4px;
      }
    }
  }

  &:hover {
    .volume {
      -webkit-transition: all ease-in-out 0.2s;
      transition: all ease-in-out 0.2s;
      opacity: 1 !important;
    }
    .visualSlider {
      filter: brightness(1.1);
    }
  }
  &:active {
    .volume {
      color: #fff !important;
    }
  }
}


</style>
