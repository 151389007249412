<template>
<div id="explore" class="page-content">
 <div class="body">

  <div class="overflow">

   <div class="most-popular set-width">

    <Carousel :items-to-show="1" :mouseDrag="true">

      <slide v-for="item in showcaseMixes" :key="item.id" class="item" @click.stop="$root.pageURL = 'singlePage', $root.viewingPage = { contentID: item.id }">
        <div class="text-copy">
         <h1>{{item.name}}</h1>
         <p>{{item.description}}</p>
         <div class="button" name="button">Listen</div>
        </div>
        <img :src="item.imageURL" >
     </slide>

     <template #addons>
      <Pagination />
     </template>
    </Carousel>

   </div>

   <div class="copy set-width">
     <div class="text-content">
       <h2>Browse Collection</h2>
     </div>
   </div>

   <div class="brand-mixes set-width">

    <div class="item" @click.stop="$root.pageURL = 'singlePage', $root.viewingPage = { contentID: item.id }" v-for="item in generalMixes" :key="item.id">
     <div class="overflow-wrapper">
      <img :src="item.imageURL" >
     </div>

     <div class="text-copy">
      <h1>{{item.name}}</h1>
      <p>{{item.description}}</p>
     </div>
    </div>

   </div>


   <!-- <div class="title set-width">
    <h2>Community Mixes</h2>
   </div>

   <div class="community-mixes set-width">
    <ul>
     <li v-for="item in publicMixes" :key="item.id" :mixID="item.id" @click.stop="$root.pageURL = 'singlePage', $root.viewingPage = { contentID: item.id }">
      <div class="info">
       <div class="mix-title">
        {{ item.name }}
       </div>
       <small class="mix-displayName">
        Created By {{ item.displayName }}
       </small>
      </div>
      <div class="button" name="button">Listen</div>
     </li>
    </ul>

   </div> -->

  </div>


 </div>

</div>
</template>

<script>
import {
 projectFirestore
} from '../main'

import 'vue3-carousel/dist/carousel.css'
import {
 Carousel,
 Slide,
 Pagination
} from 'vue3-carousel'

export default {
 components: {
  Carousel,
  Slide,
  Pagination
 },
 data() {
  return {
   publicMixes: [],
   showcaseMixes: [],
   generalMixes: [],
  };
 },
 mounted: function() {
  this.onLoad()
 },
 methods: {

  onLoad() {
   // projectAuth.onAuthStateChanged((user) => {
   //  if (user) {

     const brandMixesCollection = projectFirestore.collection('brand-mixes');

     brandMixesCollection.onSnapshot(async (querySnapshot) => {
      // Clear the personalMixes array before adding new items
      this.showcaseMixes = [];
      this.generalMixes = [];

      querySnapshot.forEach((doc) => {

        if (doc.data().category === 'Showcase') {
           let docData = []
           docData = doc.data();
           docData.id = doc.id;
           this.showcaseMixes.push(docData);
        } else if (doc.data().category === 'General Collection') {
          let docData = []
          docData = doc.data();
          docData.id = doc.id;
          this.generalMixes.push(docData);
        }

      });
     });

     // const publicMixesCollection = projectFirestore.collection('public-mixes').where("approve", "==", true).limit(50);
     //
     // publicMixesCollection.onSnapshot(async (querySnapshot) => {
     //  // Clear the personalMixes array before adding new items
     //  this.publicMixes = [];
     //
     //  querySnapshot.forEach((doc) => {
     //   let docData = []
     //   docData = doc.data();
     //   docData.id = doc.id;
     //   this.publicMixes.push(docData);
     //  });
     // });

   //  }
   // });
  },

 },
};
</script>

<style lang="scss">
#explore {
    .copy {
      .text-content {
        @media only screen and (max-width: 1501px) {
          padding-left: 14px;
        }
      }
        &:before {
            display: none;
        }
    }

    .overflow {
      width: 100%;
      padding-bottom: 100px !important;
      max-height: calc(100vh - 149px) !important;
      overflow-x: hidden !important;

        @media only screen and (max-width: 1024px) {
          max-height: calc(100vh - 115px) !important;
        }

        .title {
            margin-top: 70px !important;
            @media only screen and (max-width: 650px) {
              margin-top: 30px !important;

            }

            p {
                color: var(--base-font-colour);
            }
        }

    }

    .most-popular {
        position: relative;
        display: flex;
        padding-top: 12px !important;


        section {

            .item {
                position: relative;
                overflow: hidden;
                border-radius: 16px;
                border: solid 1px var(--mixer-border);
                cursor: pointer;
                box-shadow: 0 20px 20px -20px #00000073;
                transition: all ease-in-out 0.5s;
                // max-width: calc(100% - 5px);

                @media only screen and (max-width: 650px) {
                  max-width: calc(100% - -4px);
                }
                &:hover {
                    transition: all ease-in-out 0.5s;

                    border: solid 1px var(--primary-color);

                    .text-copy {
                        margin-top: -60px;
                        transition: all ease-in-out 0.5s;
                    }
                }
                .text-copy {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    color: #ffffff;
                    transition: all ease-in-out 0.5s;
                    z-index: 1;

                    h1 {
                        text-shadow: 4px 4px 33px #000000;
                        font-size: 3rem;
                        line-height: 3.5rem;
                        margin-bottom: 12px;

                        @media only screen and (max-width: 1024px) {
                          font-size: 4vw;
                          line-height: 4.5vw;
                        }
                        @media only screen and (max-width: 650px) {
                          font-size: 6vw;
                          line-height: 6.5vw;
                        }

                    }
                    p {
                        font-size: 1.2rem;
                        line-height: 1.5rem;
                        text-shadow: 4px 2px 12px #000000;
                        max-width: 50vmin;
                        margin: 0;
                        font-weight: normal;
                        @media only screen and (max-width: 1024px) {
                          font-size: 2vw;
                          line-height: 2.5vw;
                        }
                        @media only screen and (max-width: 650px) {
                          font-size: 3vw;
                          line-height: 4vw;
                        }
                    }
                    .button {
                        font-size: 1.2rem;
                        line-height: 1.5rem;
                        max-width: 50vmin;
                        margin: 0;
                        text-align: left;
                        font-weight: bold;
                        cursor: pointer;
                        border-radius: 4px;
                        background: var(--primary-color);
                        color: #000000;
                        padding: 8px 16px;
                        font-family: "Manrope", sans-serif !important;
                        border: none;
                        transition: all ease-in-out 0.2s;
                        margin-top: 2.5vmin;

                        @media only screen and (max-width: 1024px) {
                          font-size: 2vw;
                          padding: 8px 20px;
                        }
                        @media only screen and (max-width: 650px) {
                          font-size: 14px;
                          padding: 4px 16px;
                        }

                        &:not(:disabled) {
                            &:hover {
                                background-color: #deb320;
                            }
                            &:active {
                                background-color: #efc32b;
                            }
                            &:focus {
                                z-index: 1;
                                position: relative;
                                outline: none;
                                box-shadow: 0 0 0 3px #c09707;
                            }
                        }

                    }
                }
                img {
                    width: 100%;
                    margin: -6px 0;

                    @media only screen and (max-width: 650px) {
                      width: 200%;
                    }
                }
            }
        }

    }
    .brand-mixes {
        position: relative;
        display: flex;
        padding: 2px 34px 0;
        flex-wrap: wrap;
        .overflow-wrapper {
            overflow: hidden;
            border-radius: 10px;

        }
        .item {
            max-width: calc(33.333% - 34px);

            @media only screen and (max-width: 1000px) {
              max-width: calc(50% - 34px);
            }
            @media only screen and (max-width: 650px) {
              max-width: 100%;
              margin-right: -4px;
            }
            cursor: pointer;
            background: var(--mixer-background);
            padding: 8px 8px 18px;
            border-radius: 10px;
            border: solid 1px var(--mixer-border);
            transition: all ease-in-out 0.2s;
            margin: 8px;
            &:hover {
                transition: all ease-in-out 0.5s;
                border: solid 1px var(--primary-color);
                img {
                    margin-left: 0;
                    transition: all ease-in-out 1.5s;

                }

            }
            .text-copy {
                padding: 4px 8px;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                color: #ffffff;
                transition: all ease-in-out 0.5s;
                z-index: 1;

                h1 {
                    font-size: 1.3em;
                    margin-bottom: 12px;
                }
                p {
                    font-size: 14px;
                    margin: 0;
                    font-weight: normal;
                    text-align: left;
                    color: var(--base-font-colour);
                }
                .button {
                    font-size: 1.5vmin;
                    line-height: 2vmin;
                    max-width: 50vmin;
                    margin: 0;
                    text-align: left;
                    font-weight: bold;
                    cursor: pointer;
                    border-radius: 4px;
                    background: var(--primary-color);
                    color: #000000;
                    padding: 8px 16px;
                    font-family: "Manrope", sans-serif !important;
                    border: none;
                    transition: all ease-in-out 0.2s;
                    margin-top: 2.5vmin;

                    &:not(:disabled) {
                        &:hover {
                            background-color: #deb320;
                        }
                        &:active {
                            background-color: #efc32b;
                        }
                        &:focus {
                            z-index: 1;
                            position: relative;
                            outline: none;
                            box-shadow: 0 0 0 3px #c09707;
                        }
                    }

                }
            }
            img {
                width: 130%;
                margin: -6px 0;
                border-radius: 8px;
                margin-left: -29%;
                transition: all ease-in-out 0.5s;

            }

        }

    }

    .community-mixes {
        display: flex;
        padding: 2px 34px 0;


        ul {
            margin: 0;
            padding: 0;
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            li {
                width: 100%;
                list-style: none;
                min-width: calc(33.333% - 42px);
                max-width: calc(33.333% - 42px);
                @media only screen and (max-width: 1000px) {
                  min-width: calc(100% - 42px);
                  max-width: calc(100% - 42px);
                }
                cursor: pointer;
                background: var(--mixer-background);
                padding: 12px;
                border-radius: 10px;
                border: solid 1px var(--mixer-border);
                transition: all ease-in-out 0.2s;
                font-size: 16px;
                margin: 8px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .info {
                    width: 100%;
                    display: flex;
                    min-width: 0;
                    flex-direction: column;

                    .mix-title {
                        font-size: 16px;
                        font-weight: bold;
                        text-transform: capitalize;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        max-width: 90%;
                    }
                    .mix-displayName {
                        font-size: 14px;
                        opacity: 0.5;
                    }
                }
                .button {
                    font-size: 16px;
                    padding: 8px 12px;
                    margin: 0;
                    height: auto;
                    border-radius: 5px;
                    border: solid 1px var(--base-font-colour);
                }
                &:hover {
                    .button {
                        border-color: var(--navigation-background-border);
                        background-color: var(--body-background-colour-bottom);
                    }

                }
            }
        }
    }

}
.carousel__viewport {
    overflow: hidden;
}
.carousel__track {
    // gap: 22px;
}
.carousel__next,
.carousel__prev {
    color: white;
}
.carousel__prev {
    margin-left: 84px;
}
.carousel__next {
    margin-right: 44px;
}
.carousel__pagination {
    justify-content: center;
    margin: 36px 60px 20px 0;
    @media only screen and (max-width: 1024px) {
      margin: 36px 60px 20px 0;
    }
}
.carousel__pagination-button::after {
    background-color: var(--mixer-bar);
    width: 30px;
    height: 5px;
    border-radius: 100px;
}
.carousel__pagination-button--active::after,
.carousel__pagination-button:hover::after {
    background-color: var(--base-font-colour);
}
</style>
