import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';
import 'firebase/compat/functions';
import 'firebase/compat/storage';


let app = '';
var firebaseConfig = {
  apiKey: "AIzaSyAtLt5h1mTSvj9vgAiAQgf23b6Ok8wApr0",
  authDomain: "appmosphere-e3b5c.firebaseapp.com",
  projectId: "appmosphere-e3b5c",
  storageBucket: "appmosphere-e3b5c.appspot.com",
  messagingSenderId: "777060012636",
  appId: "1:777060012636:web:d4acaadc4d840816598c99",
  measurementId: "G-9YQTEL7VFH"
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
const projectFirestore = firebaseApp.firestore();
const projectAuth = firebase.auth();
const projectFunctions = firebase.functions();
const projectStorage = firebase.storage();

export { projectAuth, projectFirestore, projectFunctions, projectStorage };
firebaseApp.analytics();

projectAuth.onAuthStateChanged((user) => {
  if (!user) {
    //If the user is signed out disallow view
    store.state.userToken.signedin = false
    store.state.userToken.purchase = null
    store.state.userToken.displayName = null
    store.state.userToken.allowCheck = null
  } else {
    //If the user is signed in allow view
    store.state.userToken.signedin = true

    projectFirestore.collection('users').doc(user.uid).get().then((doc) => {
      store.state.userToken.purchase = doc.data().purchased.toDate().toDateString()
      store.state.userToken.displayName  = doc.data().displayName
      store.state.userToken.allowCheck = user.uid

    });
  }
  if (!app) {
    app = createApp(App).use(store).mount("#app");
  }
});
