<!-- MainComponent.vue -->
<template>
  <form class="login-form" @submit.prevent="submit">
    <span @click="this.$root.overlayToggle()" class="close material-symbols-rounded"> &#xe5cd; </span>

    <div class="header">
      <h2>Your Account</h2>
      <p>You have been a member since <br/><span class="required">{{$store.state.userToken.purchase}}</span></p>
    </div>

    <button @click="logout()" name="button">Logout</button>
  </form>

</template>

<script>
import { getAuth } from "firebase/auth";
import store from '@/store'

export default {
  props: {

  },
  data() {
    return {
      email: null,
      password: null,
      errorMessage: false,
      purchasedData: null
    };
  },
  mounted: function(){
  },
  methods: {
    logout() {
      getAuth().signOut().then(() => {
        store.state.userToken.signedin === false
        this.$root.login = false
        this.$root.overlayToggle()
        this.$root.loggedOutNoti = true
        setTimeout(() => {
          this.$root.loggedOutNoti = false;
        }, 6000);
      });
    },


  },
};
</script>
