<!-- MainComponent.vue -->
<template>
  <form class="login-form" @submit.prevent="submit">
    <span @click="this.$root.overlayToggle()" class="close material-symbols-rounded"> &#xe5cd; </span>

    <div class="header">
      <h2>Login</h2>
      <p>Save custom mixes by logging in. </p>
    </div>

    <p class="required" v-if="errorMessage"> The password is invalid or the account does not exist. </p>


    <div class="input-group">
      <label for="email">E-mail Address</label>
      <input
        type="text"
        id="email"
        v-model="email"
        placeholder="Enter your email address"
       />
    </div>

    <div class="input-group">
      <label for="password">Password</label>
      <input
        type="password"
        id="password"
        v-model="password"
        placeholder="Enter you password"

       />
    </div>


    <button name="button">Login</button>

    <div class="footer">
      <p><small>Don't have an account? <u @click="this.$root.upgradeAccount()">Create Account</u></small> </p>
    </div>


  </form>

</template>

<script>
import { projectAuth } from '../main'
import store from '@/store'

export default {
  props: {

  },
  data() {
    return {
      email: null,
      password: null,
      errorMessage: false
    };
  },
  methods: {
    submit() {
      projectAuth.signInWithEmailAndPassword(this.email, this.password).then(() => {
          store.state.userToken.signedin === true
          this.$root.login = false
          this.$root.loggedInNoti = true
          setTimeout(() => {
            this.$root.loggedInNoti = false;
          }, 6000);
        }).catch(() => {
          this.errorMessage = true
        });


    },

  },
};
</script>
<style lang="scss" scoped>
.upgradeModel {
  &:after {
    background-image: url('@/assets/upgrade-stars.png');
    content: "";
    display: block;
    position: absolute;
    width: 0%;
    top: 0;
    height: 100%;
    width: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    z-index: -1;
  }

  .header {
    // gap: 12px;
    padding-bottom: 12px !important;
  }

  .feature-container {
    margin-bottom: 4px !important;
  }

  .login-form {
    width: 100%;
  }
  .footer {
    width: 100%;
  }
}
</style>
