<!-- MainComponent.vue -->
<template>
  <div class="login-form" id="setTimers" @submit.prevent="submit">
    <span @click="this.$root.overlayToggle()" class="close material-symbols-rounded"> &#xe5cd; </span>

    <div class="header">
      <h2>Set a sleep timer</h2>
      <p>All sounds will stop once time has ended.</p>
    </div>

    <div class="input-container">

          <div class="input-group">

            <button class="timer-button" @click="countDownTimer(1800000)">
              <div>30 Minutes</div>
            </button>

            <button class="timer-button" @click="countDownTimer(3600000)">
              <div>1 Hour</div>
            </button>

            <button class="timer-button" @click="countDownTimer(5400000)">
              <div>1.5 Hours</div>
            </button>

            <button class="timer-button" @click="countDownTimer(7200000)">
              <div>2 Hour</div>
            </button>

            <button class="timer-button" @click="countDownTimer(9000000)">
              <div>2.5 Hours</div>
            </button>

            <button class="timer-button" @click="countDownTimer(10800000)">
              <div>3 Hour</div>
            </button>
          </div>

          <div class="input-group reset" v-if="$root.countdownTime">
            <button class="timer-button" @click="clearCountdownTimer()">
              <div>{{$root.countdownTime}} Clear Timer</div>
            </button>
          </div>
    </div>



  </div>

</template>

<script>

export default {
  props: {

  },
  data() {
    return {

    };
  },
  methods: {
    // Function to start the countdown timer
    countDownTimer(time) {
      this.$root.countdownTime = 0;
      this.clearCountdownTimer()

      // Convert time from milliseconds to seconds
      let remainingTime = time / 1000;

      // Set the initial countdown time
      const initialMinutes = Math.floor(remainingTime / 60);
      const initialSeconds = remainingTime % 60;
      this.$root.countdownTime = `${String(initialMinutes).padStart(2, '0')}:${String(initialSeconds).padStart(2, '0')}`;

      // Start the countdown interval
      this.timerInterval = setInterval(() => {
        remainingTime--;

        if (remainingTime < 0) {
          clearInterval(this.timerInterval);
          this.$root.$refs.customSoundMixer.stopAllSounds();
          this.$root.countdownTime = 0;
          return;
        } else {
          const minutes = Math.floor(remainingTime / 60);
          const seconds = remainingTime % 60;
          this.$root.countdownTime = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        }


      }, 1000);

    },

    // Function to clear the countdown timer
    clearCountdownTimer() {
      console.log("clear timer")
      this.$root.countdownTime = 0;
        clearInterval(this.timerInterval);
        this.timerInterval = null;

    }


  },
};
</script>

<style lang="scss" scoped>

#setTimers {
  @media only screen and (max-width: 650px) {
    margin: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    height: 100% !important;
    border-radius: 0 !important;
    border: none !important;
    max-width: calc(100% - 36px) !important;
  }
}

.header {
  @media only screen and (max-width: 650px) {

    width: calc(100% - 10px);
  }
  margin-bottom: 30px;
    padding-right: 10px !important;
}
  #app {

    .input-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: calc(100% - 145px);
      justify-content: space-around;

      > .input-group {
        &:first-child {
          height: 100%;
          margin-top: 0 !important;
        }
      }
    }
    .input-group {
      gap: 0 !important;


      &:first-child {
        margin-top: 30px !important;
      }
      button.timer-button {
        background: transparent;
        border: solid 2px var(--base-font-colour);
        justify-content: center;
        align-items: center;
        color: var(--vc-clr-white);
        font-weight: 400;
        font-size: 16px;
        gap: 21px;
        display: flex;
        margin-top: 0 !important;

        &:hover {
          background: #2C2F5B !important;
          border-color: var(--mixer-bar) !important;
          .material-symbols-rounded {
            transition: all ease-in-out 0.2s;
            font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 200, "opsz" 48;
          }
        }
      }

      &.reset {
        button.timer-button {
          max-width: 100% !important;
        }
      }
    }


  }
</style>
