<template>
<div id="customSounds" class="page-content">
 <div class="body">



  <div class="view-page" v-if="$root.pageURL === 'singlePage'">
    <PageView />
  </div>


  <div class="overflow" :class="{'noScroll': $root.scrolling, 'safari': $root.isSafari === true}">

    <div class="copy" v-if="$root.pageURL != 'singlePage'">
      <div class="text-content">
        <h2>Create your space</h2>
        <p>Experiment with different combinations of sounds to create your unique soundscape. </p>
      </div>
      <div class="icon-groups">
        <button class="filter" @click="$root.filters = !$root.filters">
          <span class="material-symbols-rounded"> &#xef4f; </span>
        </button>
      </div>

    </div>

   <div class="sound-row" v-show="currentPresetId === 1 || currentPresetId === 2">
    <h2>Widlife</h2>
    <div class="mixers">
     <MainComponent ref="Frogs" name="Frogs" label="Frogs" :audioSrc="require('@/assets/sounds/wildlife/frogs.mp3')" :icon="require('@/assets/icons/frog.png')" />
     <MainComponent ref="CroakingFrog" name="CroakingFrog" label="Croaking Frog" :audioSrc="require('@/assets/sounds/wildlife/noisy-frog.mp3')" :icon="require('@/assets/icons/croaking-frog.png')" />
     <MainComponent ref="ChirpingBird" name="ChirpingBird" label="Chirping Bird" :audioSrc="require('@/assets/sounds/wildlife/bird-chriping.mp3')" :icon="require('@/assets/icons/bird.png')" />
     <MainComponent ref="CountrysideBirds" name="CountrysideBirds" label="Countryside Birds" :audioSrc="require('@/assets/sounds/wildlife/countryside-birds.mp3')" :icon="require('@/assets/icons/country-side-birds.png')" />
     <MainComponent ref="DistantBirds" name="DistantBirds" label="Distant Birds" :audioSrc="require('@/assets/sounds/wildlife/distant-birds.mp3')" :icon="require('@/assets/icons/distant-birds.png')" />
     <MainComponent ref="CluckingChicken" name="CluckingChicken" label="Chickens" :audioSrc="require('@/assets/sounds/wildlife/chicken.mp3')" :icon="require('@/assets/icons/chicken.png')" />
     <MainComponent ref="Seagulls" name="Seagulls" label="Seagulls" :audioSrc="require('@/assets/sounds/wildlife/segulls.mp3')" :icon="require('@/assets/icons/seagull.png')" />

     <!-- <MainComponent ref="Owl" name="Owl" label="Owl - MISSING" :audioSrc="require('@/assets/sounds/Owl.mp3')" :icon="require('@/assets/icons/owl.png')"  /> -->
     <MainComponent ref="Cricket" name="Cricket" label="Cricket" :audioSrc="require('@/assets/sounds/wildlife/cricket-chirp.mp3')" :icon="require('@/assets/icons/cricket.png')" />
     <MainComponent ref="Woodpecker" name="Woodpecker" label="Woodpecker" :audioSrc="require('@/assets/sounds/wildlife/woodpecker.mp3')" :icon="require('@/assets/icons/woodpecker.png')" />
     <MainComponent ref="Ducks" name="Ducks" label="Ducks" :audioSrc="require('@/assets/sounds/wildlife/ducks.mp3')" :icon="require('@/assets/icons/duck.png')" />

    </div>
   </div>

   <div class="sound-row" v-show="currentPresetId === 1 || currentPresetId === 3">
    <h2>Outdoor</h2>
    <div class="mixers">
     <MainComponent ref="Blizzard" name="Blizzard" label="Blizzard" :audioSrc="require('@/assets/sounds/outdoor/cold-wind.mp3')" :icon="require('@/assets/icons/blizzard.png')" />
     <MainComponent ref="StrongWind" name="StrongWind" label="Strong Wind" :audioSrc="require('@/assets/sounds/outdoor/strong-wind.mp3')" :icon="require('@/assets/icons/strong-wind.png')" />
     <MainComponent ref="HowlingWind" name="HowlingWind" label="Howling Wind" :audioSrc="require('@/assets/sounds/outdoor/howling-wind.mp3')" :icon="require('@/assets/icons/howing-wind.png')" />
     <MainComponent ref="GentalWind" name="GentalWind" label="Gental Wind" :audioSrc="require('@/assets/sounds/outdoor/gental-wind.mp3')" :icon="require('@/assets/icons/gental-wind.png')" />
     <MainComponent ref="CaveWind" name="CaveWind" label="Cave Wind" :audioSrc="require('@/assets/sounds/outdoor/cave-wind.mp3')" :icon="require('@/assets/icons/cave-wind.png')" />
    </div>
   </div>

   <div class="sound-row" v-show="currentPresetId === 1 || currentPresetId === 9">
    <h2>Thunder</h2>
    <div class="mixers">
     <MainComponent ref="DistantThunder" name="DistantThunder" label="Distant Thunder" :audioSrc="require('@/assets/sounds/thunder/distant-thunder.mp3')" :icon="require('@/assets/icons/distant-thunder.png')" />
     <MainComponent ref="CloseThunder" name="CloseThunder" label="Close Thunder" :audioSrc="require('@/assets/sounds/thunder/close-thunder.mp3')" :icon="require('@/assets/icons/overhead-thunder.png')" />
     <MainComponent ref="ThunderStrikes" name="ThunderStrikes" label="Thunder Strikes" :audioSrc="require('@/assets/sounds/thunder/strong-thunder.mp3')" :icon="require('@/assets/icons/thunder-strikes.png')" />
    </div>
   </div>


   <div class="sound-row" v-show="currentPresetId === 1 || currentPresetId === 8">
    <h2>Fire</h2>
    <div class="mixers">
     <MainComponent ref="Firepit" name="Firepit" label="Firepit" :audioSrc="require('@/assets/sounds/fire/fire-pit.mp3')" :icon="require('@/assets/icons/firepit.png')" />
     <MainComponent ref="Fireplace" name="Fireplace" label="Fireplace" :audioSrc="require('@/assets/sounds/fire/fireplace.mp3')" :icon="require('@/assets/icons/fireplace.png')" />
     <MainComponent ref="RoaringFire" name="RoaringFire" label="Roaring Fire" :audioSrc="require('@/assets/sounds/fire/roaring-fire.mp3')" :icon="require('@/assets/icons/roading-fire.png')" />
     <MainComponent ref="CampFire" name="CampFire" label="Campfire" :audioSrc="require('@/assets/sounds/fire/campfire.mp3')" :icon="require('@/assets/icons/campfire.png')" />
    </div>
   </div>

   <div class="sound-row" v-show="currentPresetId === 1 || currentPresetId === 4">
    <h2>Water</h2>
    <div class="mixers">
     <MainComponent ref="CrashingWaves" name="CrashingWaves" label="Crashing Waves" :audioSrc="require('@/assets/sounds/water/crashing-waves.mp3')" :icon="require('@/assets/icons/wave.png')" />
     <!-- <MainComponent ref="GentalWaves" name="GentalWaves" label="Gental Waves CHANGE ME" :audioSrc="require('@/assets/sounds/water/gental-waves.mp3')" :icon="require('@/assets/icons/gental-waves.png')" /> -->
     <MainComponent ref="BabblingBrook" name="BabblingBrook" label="Babbling Brook" :audioSrc="require('@/assets/sounds/water/babbling-brook.mp3')" :icon="require('@/assets/icons/babblingbrook.png')" />
     <MainComponent ref="Waterfall" name="Waterfall" label="Waterfall" :audioSrc="require('@/assets/sounds/water/waterfall.mp3')" :icon="require('@/assets/icons/waterfall.png')" />
     <MainComponent ref="Stream" name="Stream" label="Stream" :audioSrc="require('@/assets/sounds/water/river.mp3')" :icon="require('@/assets/icons/stream.png')" />
     <MainComponent ref="Rainonatent" name="Rainonatent" label="Rain on a tent" :audioSrc="require('@/assets/sounds/water/tent-rain.mp3')" :icon="require('@/assets/icons/rain-on-tent.png')" />
     <MainComponent ref="Rainonawindow" name="Rainonawindow" label="Rain on a window" :audioSrc="require('@/assets/sounds/water/window-rain.mp3')" :icon="require('@/assets/icons/rain-on-window.png')" />
     <MainComponent ref="HeavyRain" name="HeavyRain" label="Heavy Rain" :audioSrc="require('@/assets/sounds/water/heavy-rain.mp3')" :icon="require('@/assets/icons/heavy-rain.png')" />
     <MainComponent ref="MediumRain" name="MediumRain" label="Medium Rain" :audioSrc="require('@/assets/sounds/water/medium-rain.mp3')" :icon="require('@/assets/icons/medium-rain.png')" />
     <MainComponent ref="LightRain" name="LightRain" label="Light Rain" :audioSrc="require('@/assets/sounds/water/light-rain.mp3')" :icon="require('@/assets/icons/light-rain.png')" />
    </div>
   </div>

   <div class="sound-row" v-show="currentPresetId === 1 || currentPresetId === 5">
    <h2>Urban</h2>
    <div class="mixers">
     <MainComponent ref="Traffic" name="Traffic" label="City Traffic" :audioSrc="require('@/assets/sounds/urban/city-trafic.mp3')" :icon="require('@/assets/icons/traffic.png')" />
     <MainComponent ref="WindChime" name="WindChime" label="Wind Chime" :audioSrc="require('@/assets/sounds/urban/windchime.mp3')" :icon="require('@/assets/icons/chimes.png')" />
     <MainComponent ref="OverheadPlane" name="OverheadPlane" label="Overhead Plane" :audioSrc="require('@/assets/sounds/urban/overhead-plane.mp3')" :icon="require('@/assets/icons/plane.png')" />
     <MainComponent ref="People" name="People" label="People" :audioSrc="require('@/assets/sounds/urban/people-talking.mp3')" :icon="require('@/assets/icons/people.png')" />
     <MainComponent ref="Train" name="Train" label="Train" :audioSrc="require('@/assets/sounds/urban/train.mp3')" :icon="require('@/assets/icons/train.png')" />
     <MainComponent ref="Construction" name="Construction" label="Construction" :audioSrc="require('@/assets/sounds/urban/construction.mp3')" :icon="require('@/assets/icons/construction.png')" />
     <!-- <MainComponent ref="Pigeons" name="Pigeons" label="Pigeons - MISSING" :audioSrc="require('@/assets/sounds/Pigeons.mp3')" :icon="require('@/assets/icons/pigeon.png')"  /> -->
     <MainComponent ref="DogsBarking" name="DogsBarking" label="Dogs Barking" :audioSrc="require('@/assets/sounds/urban/dogs.mp3')" :icon="require('@/assets/icons/dog.png')" />

    </div>
   </div>

   <div class="sound-row" v-show="currentPresetId === 1 || currentPresetId === 6">
    <h2>Home</h2>
    <div class="mixers">
     <MainComponent ref="Fan" name="Fan" label="Fan" :audioSrc="require('@/assets/sounds/home/fan.mp3')" :icon="require('@/assets/icons/fan.png')" />
     <MainComponent ref="Vacuum" name="Vacuum" label="Vacuum" :audioSrc="require('@/assets/sounds/home/vacuum.mp3')" :icon="require('@/assets/icons/vacuum.png')" />
     <MainComponent ref="LeakingTap" name="LeakingTap" label="Leaking Tap" :audioSrc="require('@/assets/sounds/home/water-drips.mp3')" :icon="require('@/assets/icons/drop.png')" />
     <MainComponent ref="WashingMachine" name="WashingMachine" label="Washing Machine" :audioSrc="require('@/assets/sounds/home/washing-machine.mp3')" :icon="require('@/assets/icons/washing-machine.png')" />
     <MainComponent ref="Keyboard" name="Keyboard" label="Keyboard" :audioSrc="require('@/assets/sounds/home/keyboard.mp3')" :icon="require('@/assets/icons/keyboard.png')" />
    </div>
   </div>

   <div class="sound-row" v-show="currentPresetId === 1 || currentPresetId === 7">
    <h2>Noise</h2>
    <div class="mixers">
     <MainComponent ref="PinkNoise" name="PinkNoise" label="Pink Noise" :audioSrc="require('@/assets/sounds/noise/pink-noise.mp3')" :icon="require('@/assets/icons/noise.png')" />
     <MainComponent ref="WhiteNoise" name="WhiteNoise" label="White Noise" :audioSrc="require('@/assets/sounds/noise/white-noise.mp3')" :icon="require('@/assets/icons/noise.png')" />
     <MainComponent ref="BrownNoise" name="BrownNoise" label="Brown Noise" :audioSrc="require('@/assets/sounds/noise/brown-noise.mp3')" :icon="require('@/assets/icons/noise.png')" />
     <MainComponent ref="Spaceship" name="Spaceship" label="Spaceship" :audioSrc="require('@/assets/sounds/noise/spaceship.mp3')" :icon="require('@/assets/icons/noise.png')" />
    </div>
   </div>
  </div>


 </div>

 <div class="right-hand-navigation" :class="{'active': $root.filters}">
   <div class="icon-groups">
     <button class="filter" @click="$root.filters = !$root.filters">
       <span class="material-symbols-rounded"> &#xe5cd; </span>
     </button>
   </div>
  <div class="presets">
   <ul>
    <li @click="currentPresetId = 1, $root.filters = false" :class="{'active': currentPresetId === 1}">All</li>
    <li @click="currentPresetId = 2, $root.filters = false" :class="{'active': currentPresetId === 2}">Widlife</li>
    <li @click="currentPresetId = 3, $root.filters = false" :class="{'active': currentPresetId === 3}">Outdoor</li>
    <li @click="currentPresetId = 9, $root.filters = false" :class="{'active': currentPresetId === 9}">Thunder</li>
    <li @click="currentPresetId = 8, $root.filters = false" :class="{'active': currentPresetId === 8}">Fire</li>
    <li @click="currentPresetId = 4, $root.filters = false" :class="{'active': currentPresetId === 4}">Water</li>
    <li @click="currentPresetId = 5, $root.filters = false" :class="{'active': currentPresetId === 5}">Urban</li>
    <li @click="currentPresetId = 6, $root.filters = false" :class="{'active': currentPresetId === 6}">Home</li>
    <li @click="currentPresetId = 7, $root.filters = false" :class="{'active': currentPresetId === 7}">Noise</li>
   </ul>

   <div class="preset-title" v-if="filteredPersonalMixes.length >= 1">Saved Mixes</div>
   <ul class="customs">
     <li v-for="item in filteredPersonalMixes" :class="{'active': this.$root.playingCustomMixId === item.id}" :key="item.id" :mixID="item.id">

       <div class="contents" @click.stop="playCustomMix(item.id), $root.filters = false">
         <div>{{ item.name }}</div>
         <div class="edit" @click.stop="this.$root.updateMixNameModalID = item.id, this.$root.updateMixNameModal = true, this.$root.updateMixNameItemName = item.name">
           <span class="material-symbols-rounded"> &#xe3c9; </span>
         </div>
       </div>

    </li>
   </ul>

  </div>
 </div>

</div>

</template>

<script>
import {
 projectAuth,
 projectFirestore
} from '../main'

import MainComponent from '@/components/MainComponent.vue';
import PageView from '@/views/PageView.vue';
// import { Howl } from 'howler';

export default {
 components: {
  MainComponent,
  PageView,

 },
 data() {
  return {
   // Vue.js data object to store the settings
   soundSettings: {},
   currentPresetId: 1,
   saveMix: false,
   personalMixes: [],
  };
 },
 mounted: function() {
  this.onLoad()
 },
 computed: {
    filteredPersonalMixes() {
      return this.personalMixes.filter(item => !item.brandMix && item.public !== true);
    }
  },
 methods: {
   playCustomMix(mixID, preventReset) {
     if (this.$root.playingCustomMixId === mixID && !preventReset) {
       this.$root.saveAdjustedMix = null;
       this.$root.playingCustomMixId = null;
       this.$root.currentSoundMix = null;

       // Stop all Howler sounds
       Object.values(this.$refs).forEach((component) => {
         if (component.sound) {
           component.stop();
         }
       });

     } else {
       const globalThis = this;

       this.$root.saveAdjustedMix = null;
       this.$root.playingCustomMixId = mixID;

       // Stop all Howler sounds
       Object.values(this.$refs).forEach((component) => {
         if (component.sound) {
           component.stop();
         }
       });

       const selectItemToPlay = this.personalMixes.find(x => x.id === mixID);
       const selectItemMix = selectItemToPlay.mix;

       this.$root.currentSoundMix = selectItemMix;

       Object.keys(selectItemMix).forEach(function(key) {
         if (selectItemMix[key].playing === true) {
           const itemToAdjust = globalThis.$refs[key];
           const itemVolumeValue = selectItemMix[key].volume;

           itemToAdjust.isPlaying = true;

           // Initialize or adjust Howler sound
           if (!itemToAdjust.sound) {
             itemToAdjust.initializeSound();
           }

           // Adjust volume
           itemToAdjust.sound.volume(itemVolumeValue / 100);
           itemToAdjust.volume = itemVolumeValue
           // Start playing or continue
           itemToAdjust.continuePlaying();
         }
       });
     }
   },
  onLoad() {

   projectAuth.onAuthStateChanged((user) => {
    if (user) {
     const uid = user.uid;
     const departmentsRef = projectFirestore.collection('users').doc(uid).collection('personal-mixes');

     departmentsRef.onSnapshot(async (querySnapshot) => {
      // Clear the personalMixes array before adding new items
      this.personalMixes = [];

      querySnapshot.forEach((doc) => {
       // Get the document data and add the document ID to the object
       const docData = doc.data();
       docData.id = doc.id;

       // Push the document data to the personalMixes array
       this.personalMixes.push(docData);
      });
     });
    }
   });
  },


  stopAllSounds() {

   this.$root.saveAdjustedMix = null
   this.$root.playingCustomMixId = null
   this.$root.currentSoundMix = null
   // Call the stop method for each MainComponent instance
   Object.values(this.$refs).forEach((component) => {
    component.stop();
    // Show the confirmation message
    this.$root.stoppedAllSounds = true;
    setTimeout(() => {
     this.$root.stoppedAllSounds = false;
    }, 2000);
   });
  },

  pauseAllSounds() {

   // Call the stop method for each MainComponent instance
   Object.values(this.$refs).forEach((component) => {

    component.pause();
    // Show the confirmation message
    this.$root.stoppedAllSounds = true;
    setTimeout(() => {
     this.$root.stoppedAllSounds = false;
    }, 2000);
   });
  },
  continueAllSounds() {
   // Call the stop method for each MainComponent instance
   Object.values(this.$refs).forEach((component) => {

    component.continuePlaying();

    // Show the confirmation message
    this.$root.stoppedAllSounds = true;
    setTimeout(() => {
     this.$root.stoppedAllSounds = false;
    }, 2000);
   });
  },

  saveSettings() {
   // Wait for the next rendering cycle to ensure child components are ready
   this.$nextTick(() => {

    const settings = {};
    for (const key in this.$refs) {
     const component = this.$refs[key];

     if (component && component.name) {
      settings[component.name] = {
       playing: component.isPlaying,
       volume: component.volume,
      };
     }
    }

    projectAuth.onAuthStateChanged((user) => {
     const uid = user.uid

     const mixData = {
      name: this.name,
      created: new Date(),
      createdBy: uid,
      public: this.makePublic,
      approve: false,
      mix: settings,
      displayName: this.name
     }

     projectFirestore.collection("users").doc(uid).collection("personal-mixes").doc().set(mixData).then(() => {
      if (this.makePublic === true) {
       projectFirestore.collection("public-mixes").doc().set(mixData)
      }
      // Show the save confirmation message
      this.saveMix = false;
      this.$root.showSaveMessage = true;
      setTimeout(() => {
       this.$root.showSaveMessage = false;
      }, 6000);
     });
    })

   });
  },
  updateSavedMix(docID) {

   // Wait for the next rendering cycle to ensure child components are ready
   this.$nextTick(() => {

    const settings = {};
    for (const key in this.$refs) {
     const component = this.$refs[key];

     if (component && component.name) {
      settings[component.name] = {
       playing: component.isPlaying,
       volume: component.volume,
      };
     }
    }

    projectAuth.onAuthStateChanged((user) => {
     const uid = user.uid

     const mixData = {
      mix: settings,
     }

     projectFirestore.collection("users").doc(uid).collection("personal-mixes").doc(docID).update(mixData).then(() => {
      // Show the save confirmation message
      this.saveMix = false;
      this.$root.showSaveMessage = true;
      this.$root.saveAdjustedMix = false

      setTimeout(() => {
       this.$root.showSaveMessage = false;
      }, 6000);
     });
    })

   });
  },


 },
};
</script>

<style lang="scss" scoped>

.customs {
  li {

    .contents {
      display: inline-flex;
      span {
        font-size: 18px;
        margin: 4px 0px 4px 8px;
      }
    }

  }
}
#customSounds {

  .copy {
    .text-content{
      width: 100%;
      @media only screen and (max-width: 650px) {
        h2 {
          font-size: 18px;
        }
        p {
          font-size: 14px;
        }
      }
    }
    display: flex;
    gap: 30px;

    .icon-groups {
      .filter {
        position: fixed;
        bottom: 22px;
        right: 22px;
        background: var(--vc-clr-white);
        color: var(--body-background-colour-bottom);
        width: 55px;
        height: 55px;
        z-index: 4;

        &:hover {
          > span {
            font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 200, "opsz" 48;
          }
        }
      }
    }
  }

  .filter {
    @media only screen and (min-width: 1025px) {
      display: none;
    }
    appearance: none;
    background: none;
    border: solid 1px #fff;
    width: 44px;
    height: 44px;
    padding: 12px;
    margin: 8px;
    color: #ffffff;
    display: flex;
    text-decoration: none;
    border-radius: 12px;
    font-size: 26px;
    cursor: pointer;
    position: relative;
    align-items: center;
    justify-content: center;
    > span {
      font-size: 22px;
    }

    @media only screen and (max-width: 650px) {
      margin: 2px;
    }

    &.active {
      background: #2C2F5B;
      span {
        font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 200, "opsz" 48;
      }
    }

    &:hover{
      background: #2C2F5B;
      span {
        font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 200, "opsz" 48;
      }
      div {
        display: block;
      }
    }
    &:focus-visible {
     background: var(--mixer-bar);
     span {
       font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 200, "opsz" 48;
     }
     div {
       display: block;
     }
   }


    div {
      display: none;
      position: absolute;
      bottom: -45px;
      margin: auto;
      right: 0;
      width: max-content;
      font-size: 14px;
      background: var(--navigation-background-color);
      padding: 8px 12px;
      border-radius: 8px;
    }

  }
  .overflow {
    max-height: calc(100vh - 199px);
    @media only screen and (max-width: 1024px) {
      padding: 0 8px;
      max-width: calc(100% - 16px);
    }
  }
}


</style>
