<template>
  <div class="upgradeModel">
    <span @click="overlayToggle()" class="close material-symbols-rounded"> &#xe5cd; </span>

    <div class="header">
      <!-- <span class="material-symbols-rounded"> &#xe898; </span> -->
      <h2>Create account</h2>
      <p>Unlock the ability to save custom sound mixes.</p>
    </div>

    <form class="login-form" @submit.prevent="submit">
      <span @click="$root.overlayToggle()" class="close material-symbols-rounded"> &#xe5cd; </span>

      <p class="required" v-if="errorMessage">The password is invalid or the account does not exist.</p>

      <div class="input-group">
        <label for="email">E-mail Address</label>
        <input type="text" id="email" v-model="email" placeholder="Enter your email address" />
      </div>

      <div class="input-group">
        <label for="password">Password</label>
        <input type="password" id="password" v-model="password" placeholder="Enter your password" />
      </div>

      <button type="submit">Create Account</button>

      <div class="footer">
        <p><small>Already have an account? <u @click="this.$root.account()">Login</u></small> </p>
      </div>
    </form>
  </div>
</template>

<script>
import { projectAuth, projectFirestore } from '../main'; // Adjust import path as per your project structure

export default {
  data() {
    return {
      email: null,
      password: null,
      errorMessage: false
    };
  },
  methods: {
    submit() {
      // Create user account with email and password
      projectAuth.createUserWithEmailAndPassword(this.email, this.password)
        .then(async (userCredential) => {
          // Signed in, create additional user data in Firestore
          const user = userCredential.user;
          const userData = {
            uid: user.uid,
            purchased: new Date(),
            // Add other user data if needed
          };
          await projectFirestore.collection('users').doc(user.uid).set(userData);

          // Example: Update application state or notify user upon successful account creation
          this.$root.upgrade = false; // Example: Hide login form
          this.$root.loggedInNoti = true; // Example: Show notification
          setTimeout(() => {
            this.$root.loggedInNoti = false; // Hide notification after 6 seconds
          }, 6000);
        })
        .catch((error) => {
          // Handle account creation errors
          console.error('Error creating account:', error);
          this.errorMessage = true; // Show error message in UI
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.upgradeModel {
  &:after {
    background-image: url('@/assets/upgrade-stars.png');
    content: "";
    display: block;
    position: absolute;
    width: 0%;
    top: 0;
    height: 100%;
    width: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    z-index: -1;
  }

  .header {
    // gap: 12px;
    padding-bottom: 12px !important;
  }

  .feature-container {
    margin-bottom: 4px !important;
  }

  .login-form {
    width: 100%;
  }
}
</style>
