import { createStore } from 'vuex'

export default createStore({
  state: {
    userToken: {
      signedin: false,
      displayName: false,
      purchase: false,
      roleCheck: null,
    },
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
