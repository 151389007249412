<!-- MainComponent.vue -->
<template>

<div class="player-container">
  <div :class="{'expanded': $root.saveAdjustedMix == true , 'shrink':  $root.saveAdjustedMix == false && $root.playingCustomMixId != null}">

     <div class="stop button" @click="playerStopAll()">
      <span class="material-symbols-rounded"> &#xef71; </span>
      <div class="tooltip">Stop All Sounds</div>
     </div>

     <div class="save button" @click="saveMixPannel()">
      <span class="material-symbols-rounded"> &#xf884; </span>
      <div class="tooltip">Create Custom Mix</div>
     </div>

     <div class="resetMix button adjustments-1" @click="playerResetMix()"  v-show="$root.saveAdjustedMix">
      <span class="material-symbols-rounded"> &#xe28e; </span>
      <div class="tooltip">Reset Mix</div>
     </div>

     <div class="saveAndUpdate button adjustments-2" @click="playerUpdateSavedMix()" v-show="$root.saveAdjustedMix"
     :class="{'disabled': $root.pageURL == 'singlePage'}">
      <span class="material-symbols-rounded"> &#xe161; </span>
      <div class="tooltip">Update Mix</div>
     </div>

  </div>
</div>

</template>

<script>
// import {
//  projectAuth,
//  projectFirestore
// } from '../main'
import store from '@/store'

export default {
  props: {

  },
  data() {
    return {
    };
  },
  mounted: function() {
    navigator.mediaSession.setActionHandler('play', () => null);
    navigator.mediaSession.setActionHandler('pause', () => null);
  },
  methods: {
    mediaTrigger() {

    },
    playerResetMix() {

      this.shrink = true
      this.$root.$refs.customSoundMixer.playCustomMix(this.$root.playingCustomMixId, true)
      this.$root.customAdjustedItems = 0
    },
    playerUpdateSavedMix( ){
      if (this.$root.pageURL != 'singlePage') {
        this.$root.$refs.customSoundMixer.updateSavedMix(this.$root.playingCustomMixId)
      }
    },
    playerStopAll() {
      if (this.$root.playingCount >= 1) {
        this.$root.$refs.customSoundMixer.stopAllSounds()
      }
    },
    saveMixPannel() {
     if (store.state.userToken.signedin === true) {
      this.$root.saveMix = true;
     } else {
      this.$root.upgrade = true;
     }
    },


  },
};
</script>


<style lang="scss">
.player-container {
  animation-name: fadeIn-bottom;
  animation-duration: 0.5s;
  width: fit-content;
  z-index: 5;
  position: fixed;
  bottom: 22px;
  left: 22px;
  right: 22px;
  padding: 4px 4px;
  margin: auto;


 > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 112px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: rgba(1, 2, 13, 0.6);
    border: solid 1px var(--mixer-bar);
    backdrop-filter: blur(10px);
    border-radius: 8px;
    transition: all ease-in-out 0.5s;
    padding: 4px 4px;

   .button {
     justify-content: center;
     align-items: center;
     margin: 4px !important;
     border-radius: 4px !important;
     &:hover {
       background: var(--primary-color);

       .tooltip {
         right: unset !important;
         bottom: unset !important;
         top: -45px;
       }
     }
     &.disabled {
       filter: brightness(0.5);
       &:hover {
         background: none !important;
         span {
           font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 200, "opsz" 48 !important;
         }
         .tooltip {
           display: none !important;
         }
       }
     }

   }

   &.expanded {
     width: 224px;
     animation-name: widthAdjustUp;
     animation-duration: 1s;
   }
   &.shrink {
     width: 112px;
     animation-name: widthAdjustDown;
     animation-duration: 1s;
   }

   .adjustments-1 {
     animation-name: fadeInButtons-1;
     animation-duration: 0.5s;
   }
   .adjustments-2 {
     animation-name: fadeInButtons-2;
     animation-duration: 0.8s;
   }
 }


}
@keyframes widthAdjustUp {
  0% {
    width: 112px;
  }

  100% {
    width: 224px;
  }
}
@keyframes widthAdjustDown {
  0% {
    width: 224px;
  }

  100% {
    width: 112px;
  }
}
@keyframes fadeInButtons-1 {
  0% {
    opacity: 0;
    right: -12px;
  }
  50% {
    opacity: 0;
    right: -12px;
  }
  100% {
    opacity: 1;
    right: 0;
  }
}
@keyframes fadeInButtons-2 {
  0% {
    opacity: 0;
    right: -12px;
  }
  70% {
    opacity: 0;
    right: -12px;
  }
  100% {
    opacity: 1;
    right: 0;
  }
}

</style>
